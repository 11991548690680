import React, {useState} from "react";
import "../../css/tooltip.css"
import {Icon} from "semantic-ui-react";


const Tooltip = ({text}) => {
    const [hover, setHover] = useState(false);
    const iconName = () => hover ? "question circle" : "question circle outline"
    const tooltipStyle = () => hover ? {height: 120, padding: "10px 10px"} : {height: 0, padding: "0 10px"}

    return (<div className="tooltip-full-container">
        <div className="tooltip-icon-container">
            <Icon
                className="tooltip-icon"
                onMouseOver={()=>setHover(true)}
                onMouseLeave={()=>setHover(false)}
                name={iconName()} />
        </div>
        <div className="tooltip-popup-container" style={tooltipStyle()}>
            <p>{text}</p>
        </div>
    </div>)
};

export default Tooltip;