import * as React from "react"
import {
  Header, Segment, Image
} from "semantic-ui-react"
import {t} from "../../i18n";
import ShowMoreText from 'react-show-more-text';
import PremixButton from "./PremixButton";

const arrow_right = '/img/utils/arrow-right.png';

interface OwnProps {
  premix: any,
  orderCode: string,
  onDetails: (string) => void,
  basket: any
  gender?: boolean
  disabled?: boolean
}


type Props = OwnProps;

interface State {
  genderActive: boolean
}

export default class PremixBox extends React.Component<Props, State> {
  constructor(props){
    super(props);
    this.state = this.getUpdateStateData()
  }

  getUpdateStateData() {
    let genderActive = (this.props.gender && this.props.basket) ? true : false;
    return {genderActive}
  }

  componentDidUpdate(prevProps: Readonly<OwnProps>, prevState: Readonly<State>, snapshot?: any): void {
      if(prevProps.basket !== this.props.basket){
        this.setState(this.getUpdateStateData());
      }
  }

  convertName = (name) => {
    let newName = name.toLowerCase();
    return newName.charAt(0).toUpperCase() + newName.slice(1);
  }

  render() {
    return (
      <Segment basic={!this.state.genderActive} className="premix-box" disabled={this.props.disabled && this.props.gender && !this.props.basket} style={{borderRadius: '10px'}}>
          <Segment basic style={{padding: '0 40px'}}>
            <Image src={`/img/premixes/${this.props.premix.code}.png`} alt="d3" size="medium" centered/>
          </Segment>
          <div style={{paddingBottom: '5px'}}>
            <Header as='h3' style={{marginTop: '14px'}}>{this.convertName(this.props.premix.name)}</Header>
            <ShowMoreText
              lines={2}
              more={''}
              less={''}
              anchorClass=''
              expanded={false}
            >
            <p dangerouslySetInnerHTML={{__html: t(this.props.premix.desc)}}/>
            </ShowMoreText>
          </div>
          <PremixButton orderCode={this.props.orderCode} premix={this.props.premix} basket={this.props.basket}
            disabled={!this.props.gender && this.props.disabled}
          />
          
          <div id='read-more' onClick={() => (!this.props.gender && this.props.disabled) ? '' : this.props.onDetails(this.props.premix.code)} style={{cursor: 'pointer', paddingTop: 14}}>
            Dowiedź się więcej<Image src={arrow_right} alt="More" id='img-more'/>
          </div>
          
      </Segment>
    )
  }
}