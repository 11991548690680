import * as React from "react"
import '../../css/basket.css'
import {
  Button, Header, Segment, Grid, Table, Modal
} from "semantic-ui-react"
import {connect} from "react-redux";
import * as actions from "../../actions";
import _ from "lodash";
import {navigateTo} from "../../helpers/router";
import {t} from "../../i18n";
import SurveyOrder from "./surveyOrder";
import NewOrder from "./newOrder";

interface StateProps {
  cart: any
  payment: any
  order: any
  orderCheck: any
}

export function mapStateToProps(state: any): StateProps {
  return {
    cart: state.cart,
    payment: state.payuPayment,
    order: state.getNewOrder,
    orderCheck: state.checkOrder,
  };
}

interface DispatchProps {
  payuPayment: typeof actions.payuPayment,
  getNewOrder: typeof actions.getNewOrder,
}

const mapDispatchToProps = {
  payuPayment: actions.payuPayment,
  getNewOrder: actions.getNewOrder,
};

type Props = StateProps & DispatchProps;

interface State {
  order: any,
  orderLines: any,
  availablePremixes: any,
  surveyLines: any,
  orderCode: any,
  priceBase: number,
  priceDelivery: number,
  loading: boolean,
  showIngredients: boolean
  showLoginRequired: boolean
  isSurveyOrder: boolean
}

class Order extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      order: null,
      orderCode: "",
      orderLines: null,
      availablePremixes: null,
      surveyLines: null,
      priceBase: 0,
      priceDelivery: 0,
      loading: true,
      showIngredients: false,
      showLoginRequired: false,
      isSurveyOrder: false,
    }
  }

  componentDidMount(): void {
    this.getNewOrder();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState): void {
    if (prevProps.cart !== this.props.cart) {
      let activeOrder = this.props.cart.data;
      this.onOrderChange(activeOrder);
    }
    if (prevProps.payment !== this.props.payment) {
      let payment = this.props.payment;
      if (typeof window !== `undefined`)
        window.location.href = payment.redirectUri;
    }
    if (prevProps.order !== this.props.order) {
      let order = this.props.order;
      this.updateOrder(order);
    }
    if (prevProps.orderCheck !== this.props.orderCheck) {
      let order = this.props.orderCheck;
      if(order.errors){
        if(order.data.id === 'security.no.user'){
          this.setState({showLoginRequired: true})
        }
      } else if(order.data && order.data.code){
        navigateTo('/yourorder');
      }
    }
  }

  getNewOrder(){
    let params = new URLSearchParams(this.props.location.search);
    let type = params.get('type');
    if(!type){
      type = localStorage.getItem('order') ?? 'normal';
      navigateTo(`/order?type=${type}`)
    }
    let orderCode = localStorage.getItem('orderCode');
      if(type === 'survey'){
        orderCode = localStorage.getItem('surveyOrderCode');
      }
      if(orderCode){
        let code = btoa(orderCode);
        this.setState({ orderCode: orderCode}, () => this.props.getNewOrder(code));
      } else {
        navigateTo('/checkorder');
      }
  }

  updateOrder(order){
    if(order.errors){
      navigateTo('/checkorder')
    } else if(order.data){
      let activeOrder = order.data;
      if (activeOrder.status === 'created' || activeOrder.status === 'new') {
        this.onOrderChange(activeOrder);
        let pb = activeOrder['payment_methods'][0].price_base;
        let pd = activeOrder['payment_methods'][0].price_delivery;
        pb = parseFloat(pb);
        pd = parseFloat(pd);
        this.setState({priceBase: pb, priceDelivery: pd, loading: false})
      } else {
        navigateTo('/checkorder')
      }

    }
  }

  onOrderChange(activeOrder) {
    if (activeOrder) {
      this.setState({
        order: activeOrder,
        orderCode: activeOrder.code,
        orderLines: activeOrder.orderLines,
        availablePremixes: activeOrder.available_premixes,
        surveyLines: activeOrder.surveyLines,
      }, () => this.sortAP())
    }
  }

  sortAP() {
    let ap = this.state.availablePremixes;
    ap = _.sortBy(ap, 'add').reverse()
    this.setState({availablePremixes: ap});
  }

  handleShowIngredientsModal(){
    this.setState({showIngredients: true})
  }

  modalLoginRequired(){
    return (
      <Modal
        open={this.state.showLoginRequired}
        dimmer="inverted"
        centered={true}
      >
        <Modal.Header>Potrzeba zalogowania</Modal.Header>
        <Modal.Content>
          <Header as="h4">Aby złożyć zamówienie trzeba się najpierw zalogować</Header>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => navigateTo('/login', '', { state: {redirectUrl: '/checkorder'}})}>
            Zaloguj
          </Button>
          <Button secondary onClick={() => navigateTo('/register', '', { state: {redirectUrl: '/checkorder'}})}>
            Zarejestruj
          </Button>
          <Button color='grey' onClick={() => this.setState({showLoginRequired: false})}>
            Anuluj
          </Button>
      </Modal.Actions>
      </Modal>
    )
  }

  modalIngredientsSummary(){
    return (
      <Modal
        open={this.state.showIngredients}
        onClose={() => this.setState({showIngredients: false})}
        dimmer="inverted"
        centered={true}
      >
        <Modal.Header>Skład suplementu</Modal.Header>
        <Modal.Content>
          <Segment>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Table basic='very' columns={2}>
                    <Table.Body>
                      {_.map(this.state.order.summary, (element, i: number) => (
                        <React.Fragment key={i}>
                          <Header><b>{t(i)}</b></Header>
                          {_.map(element, (detail, j: number) => (
                            <React.Fragment key={j}>
                              <Table.Row>
                                <Table.Cell width={5}>{t(detail.name)}</Table.Cell>
                                <Table.Cell width={2}><span dangerouslySetInnerHTML={{__html: detail.value}}/></Table.Cell>
                              </Table.Row>
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Segment basic>
            <Grid textAlign="center">
              <Grid.Row>
                <Grid.Column>
                  <Button className={"shadow-dark"} id="btn-use-address" compact onClick={() => this.setState({showIngredients: false})}>Ukryj skład suplementu</Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Modal.Content>
      </Modal>
    )
  }

  render() {
    if (this.state.loading) {
      return (
        <Segment style={{width: "100%", height: "100vh"}} loading={true}/>
      )
    } else {
      return (
        <>
        {this.modalIngredientsSummary()}
        {this.modalLoginRequired()}
        {(this.state.surveyLines && this.state.surveyLines.length) > 0 ? 
          <SurveyOrder
            orderCode={this.state.orderCode}
            availablePremixes={this.state.availablePremixes}
            surveyLines={this.state.surveyLines}
            orderLines={this.state.orderLines}
            priceBase={this.state.priceBase}
            priceDelivery={this.state.priceDelivery}
            onShowIngredients={this.handleShowIngredientsModal.bind(this)}
          /> 
          :
          <NewOrder
            orderCode={this.state.orderCode}
            availablePremixes={this.state.availablePremixes}
            orderLines={this.state.orderLines}
            priceBase={this.state.priceBase}
            priceDelivery={this.state.priceDelivery}
            onShowIngredients={this.handleShowIngredientsModal.bind(this)}
          />
        }
        </>
      )
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);