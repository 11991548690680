import * as React from "react"
import '../../css/basket.css'
import {
  Button, Grid, Icon,
} from "semantic-ui-react"
import {connect} from "react-redux";
import * as actions from "../../actions";
import _ from "lodash";
import Tooltip from "../elements/tooltip";

interface StateProps {
}

interface OwnProps {
  premix: any,
  orderCode: string,
  basket: any
  disabled?: boolean
}

export function mapStateToProps(state: any): StateProps {
  return {
  };
}

interface DispatchProps {
  addToCart: typeof actions.addToCart,
  removeFromCart: typeof actions.removeFromCart,
  editCart: typeof actions.editCart,
}

const mapDispatchToProps = {
  addToCart: actions.addToCart,
  removeFromCart: actions.removeFromCart,
  editCart: actions.editCart,
};

type Props = StateProps & DispatchProps & OwnProps;

interface State {
}

class PremixButton extends React.Component<Props, State> {
  cartAction(action, ratio = 0): void {
    let b64Code = btoa(this.props.orderCode);
    let formData = {
      code: b64Code,
      orderLinesCode: this.props.premix.code,
    }

    if (action === 'add') {
      this.props.addToCart(formData)
    } else if (action === 'remove') {
      this.props.removeFromCart(formData)
    } else if (action === 'increase') {
      formData.ratio = ratio;
      this.props.editCart(formData)
    } else if (action === 'decrease') {
      formData.ratio = ratio;
      this.props.editCart(formData)
    }
  }

  buttonsClassName = () => {
    if(this.props.premix.code === 'z_man'){
      return 'button-man';
    } else if(this.props.premix.code === 'z_woman'){
      return 'button-woman';
    }
    return '';
  }

  addButtonText = () => {
    if(this.props.premix.code === 'z_man'){
      return <span>Wybierz Multi Mężczyzna</span>
    } else if(this.props.premix.code === 'z_woman'){
      return <span>Wybierz Multi Kobieta</span>
    } else {
      return <span>Dodaj do koszyka <b>+</b></span>
    }
  }

  render() {
    if(this.props.basket){
      if(this.props.premix.edit){
        return (<>
          {/* <p style={{color: "#00FDD4", marginBottom: "5px"}}>zmień dawkę</p> */}
          <div style={{display: "flex", justifyContent: "center", paddingTop: '26px'}} className={`basket-button-edit ${this.buttonsClassName()}`}>
            <Button disabled={!this.props.basket.edit_options.minus?.value} id='edit-minus'
                    onClick={() => this.cartAction("decrease", this.props.basket.edit_options.minus?.value)}>-</Button>
            <div id='edit-display'><p>porcja </p> &nbsp; {this.props.basket.edit_options.actual.label}</div>
            <Button disabled={!this.props.basket.edit_options.plus?.value} id='edit-plus'
                    onClick={() => this.cartAction("increase", this.props.basket.edit_options.plus?.value)}>+</Button>
          </div>
          <Grid columns={2}>
            <Grid.Column width={12}>
            {(this.props.premix.remove === 1) &&
              <div style={{textAlign: 'left'}}>
                <span id="remove-link" onClick={() => this.cartAction("remove")} style={{padding: '0 5'}}><Icon
                name="close"/>usuń z koszyka</span>
              </div>
            }
            </Grid.Column>
            <Grid.Column width={4}>
              <Tooltip text={`dopasuj swoją dawkę jeszcze bardziej za pomocą przycisków "+" lub "-"`}/>
            </Grid.Column>
          </Grid>
        </>)
      } else {
        return (
          <div style={{paddingTop: '26px'}}>
            <Button fluid className="basket-button-remove"
              onClick={() => this.cartAction('remove')}
            >
              Usuń z koszyka <b>-</b>
            </Button>
          </div>
         
        )
      }
    }
    return (
      <div style={{paddingTop: '26px'}}>
        <Button fluid className={`basket-button-add ${this.buttonsClassName()}`}
          onClick={() => this.cartAction('add')}
          disabled={this.props.disabled}
        >
          {this.addButtonText()}
        </Button>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PremixButton);