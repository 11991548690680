import React, { useState } from "react";
import { Icon, Popup } from "semantic-ui-react";


const PopupIconHover = ({ children, icon, hoverIcon }) => {
  const [hover, setHover] = useState(false);
  const iconName = () => hover ? hoverIcon : icon

  return (
      <Popup
        trigger={
          <Icon
            onMouseOver={()=>setHover(true)}
            onMouseLeave={()=>setHover(false)}
            name={iconName()} 
            size="large"
          />
        }
      >
      <Popup.Content>
        {children}
      </Popup.Content>
    </Popup>
    )
};

export default PopupIconHover;