import * as React from "react"
import {
  Button, Header, Segment, Grid, Table, Icon, Popup, Image
} from "semantic-ui-react"
import {connect} from "react-redux";
import * as actions from "../../actions";
import _ from "lodash";
import PopupIconHover from "../elements/PopupIconHover";

interface StateProps {
}

export function mapStateToProps(state: any): StateProps {
  return {
  };
}

interface DispatchProps {
  removeFromCart: typeof actions.removeFromCart,
  checkOrder: typeof actions.checkOrder,
}

const mapDispatchToProps = {
  removeFromCart: actions.removeFromCart,
  checkOrder: actions.checkOrder,
};

interface OwnProps {
  orderCode: string
  orderLines: any
  priceBase: number
  priceDelivery: number
  onShowIngredients: () => void
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
}

class OrderSummary extends React.Component<Props, State> {

  removeFromCart(premixCode){
    let b64Code = btoa(this.props.orderCode);
    let formData = {
      code: b64Code,
      orderLinesCode: premixCode,
    }
    this.props.removeFromCart(formData);
  }

  callCheckOrder(){
    this.props.checkOrder(btoa(this.props.orderCode));
  }

  calculatePrice() {
    let ol = this.props.orderLines;
    let sum = 0;
    _.forEach(ol, function (el) {
      sum += parseFloat(el.price) * el.ratio;
    })
    return sum;
  }

  iconPopup(){
    return (
      <PopupIconHover
        icon='question circle outline'
        hoverIcon='question circle'
      >
        Twój suplement jest wyjątkowy, dlatego ręcznie przygotuje, sprawdzi i spakuje go dedykowany specjalista. 
        Nie doda żadnych wypełniaczy ani konserwantów, bo produkt od razu zostanie wysłany do Ciebie.
      </PopupIconHover>
    )
  }

  render() {
    return (
      <Segment basic className="order-summary" style={{padding: 0}}>
        <Segment.Group className='shadow-dark' style={{backgroundColor: 'white'}}>
          <Segment id='basket-segment'>
            <Header id='header-order-summary'>Podsumowanie zamówienia</Header>
            <p>Twoje zamówienie to miesięczna suplementacja Pure4Me</p>
            <Table basic='very'>
              <Table.Body>
                {_.map(this.props.orderLines, ({price, name, code, ratio, remove}, i: number) => (
                  <Table.Row key={i}>
                    <Table.Cell width={10}>
                      {name}<br/>
                      {(remove === 1) &&
                      <span id="remove-link" onClick={() => this.removeFromCart(code)}><Icon
                        name="close"/>usuń pozycję</span>}
                    </Table.Cell>
                    <Table.Cell width={6} textAlign='right'>{(price * ratio).toFixed(2)} zł</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Segment>
          <Segment basic>
            <div style={{paddingBottom: "26px"}}>
            <Table basic='very' style={{marginBottom: 0}}>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Cena składników:</Table.Cell>
                  <Table.Cell textAlign="right">{this.calculatePrice().toFixed(2)} zł</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={12}>
                    <Grid columns={2}>
                      <Grid.Column width={12} className="mobile-bold">
                        Koszt przygotowania, produkcji i pakowania:
                      </Grid.Column>
                      <Grid.Column width={4} textAlign="center" verticalAlign="middle">
                        {this.iconPopup()}
                      </Grid.Column>
                    </Grid> 
                  </Table.Cell>
                  <Table.Cell width={4} textAlign="right"> {this.props.priceBase.toFixed(2)} zł</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell width={12}>Transport:</Table.Cell>
                  <Table.Cell width={4}
                              textAlign="right"> {this.props.priceDelivery.toFixed(2)} zł</Table.Cell>
                </Table.Row>

                <Popup content={`Jest to koszt miesięczny kuracji.
                Cena kuracji na 1 dzień wynosi: ${((this.calculatePrice() + this.props.priceBase + this.props.priceDelivery) / 30).toFixed(2)} zł`}
                
                trigger={
                  <Table.Row id={"sum"}>
                    <Table.Cell width={12}>
                      <sup style={{color: "red", fontSize: "1em"}}>*</sup><b>SUMA </b>
                    </Table.Cell>
                    <Table.Cell width={4} textAlign="right">
                      <Table.Cell width={4} textAlign="right"> <b>{(this.calculatePrice() + this.props.priceBase + this.props.priceDelivery).toFixed(2)} zł</b></Table.Cell>
                    </Table.Cell>
                  </Table.Row>
                }/>     
              </Table.Body>
            </Table>
            </div>
            
          </Segment>
        </Segment.Group>
    
        <Grid textAlign='center' style={{marginTop: "-56px"}}>
          <Grid.Column>
            <Button primary onClick={this.callCheckOrder.bind(this)} id='btn-order' disabled={!this.props.orderLines || this.props.orderLines.length == 0}
                    className='shadow-dark'>przejdź do zamówienia</Button>
          </Grid.Column>
        </Grid>
        <Grid>
          <Grid.Row>
            <Grid.Column textAlign='center'>
              <div style={{display: 'inline-block', textAlign: 'left'}}>
                <span style={{fontSize: "12px"}}>Cena kuracji na 1 dzień wynosi: {((this.calculatePrice() + this.props.priceBase + this.props.priceDelivery) / 30).toFixed(2)} zł</span>
                <div onClick={this.props.onShowIngredients} style={{cursor: 'pointer', textDecoration: 'underline'}}>Pokaż skład suplementu</div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderSummary);