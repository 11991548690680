import * as React from "react"
import Order from "../components/pages/order";
import Layout from "../templates/layout";
import FullApp from "../FullApp";

export default (props) => (
  <FullApp>
    <Layout {...props} router={props} isDark={true}>
      <Order location={props.location}/>
    </Layout>
  </FullApp>
);