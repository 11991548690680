import * as React from "react"
import '../../css/basket.css'
import {
  Button, Header, Segment, Divider, Container, Image, Grid, Table, Icon
} from "semantic-ui-react"
import {connect} from "react-redux";
import * as actions from "../../actions";
import _ from "lodash";
import {t} from "../../i18n";
import ShowMoreText from 'react-show-more-text';
import Tooltip from "../elements/tooltip";
import OrderSummary from "./orderSummary";


const arrow_right = '/img/utils/arrow-right.png';
const arrow_left = '/img/utils/arrow-left.png';

interface StateProps {
}

export function mapStateToProps(state: any): StateProps {
  return {
  };
}

interface DispatchProps {
  addToCart: typeof actions.addToCart,
  removeFromCart: typeof actions.removeFromCart,
  editCart: typeof actions.editCart,
  checkOrder: typeof actions.checkOrder,
  createOrder: typeof actions.createOrderWithoutSurvey,
}

const mapDispatchToProps = {
  addToCart: actions.addToCart,
  removeFromCart: actions.removeFromCart,
  editCart: actions.editCart,
  checkOrder: actions.checkOrder,
  createOrder: actions.createOrderWithoutSurvey
};

interface OwnProps {
  orderCode: string,
  availablePremixes: any,
  surveyLines: any,
  orderLines: any,
  priceBase: number,
  priceDelivery: number
  onShowIngredients: () => void
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {

}

const allIconsCodes = ['z_d3', 'z_energy', 'z_immunity', 'z_liver', 'z_man', 'z_skin', 'z_vitamins', 'z_woman', 'z_lacium', 'z_memory'];
const notVegan = ['z_omega3', 'z_joints'];
const sachetts = ['z_d3', 'z_energy', 'z_immunity', 'z_liver', 'z_man', 'z_skin', 'z_vitamins', 'z_woman'];
const pills = ['z_omega3', 'z_joints', 'z_lacium', 'z_memory'];

class SurveyOrder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
    }
  }

  cartAction(action, premixCode, ratio = 0): void {
    let b64Code = btoa(this.props.orderCode);
    let formData = {
      code: b64Code,
      orderLinesCode: premixCode,
    }
    if (action === 'add') {
      this.props.addToCart(formData)
    } else if (action === 'remove') {
      this.props.removeFromCart(formData)
    } else if (action === 'increase') {
      formData.ratio = ratio;
      this.props.editCart(formData)
    } else if (action === 'decrease') {
      formData.ratio = ratio;
      this.props.editCart(formData)
    }
  }

  callCheckOrder(){
    this.props.checkOrder(btoa(this.props.orderCode));
  }

  displayButtons(code, edit, remove) {
    let basket = this.props.orderLines;
    let isInBasket = false;
    let ratio = 1;
    let increaseRatio = null;
    let decreaseRatio = null;
    let isFirst = false;
    let label = null;


    for (let x in basket) {
      if (basket[x].code === code) {
        isInBasket = true;
        ratio = basket[x].ratio;
        if (x === '0') {
          isFirst = true;
        }
        if (basket[x].edit) {
          if (basket[x].edit_options.minus) {
            decreaseRatio = basket[x].edit_options.minus.value;
          }
          if (basket[x].edit_options.plus) {
            increaseRatio = basket[x].edit_options.plus.value;
          }
          label = basket[x].edit_options.actual.label;
        }
        break;
      }
    }

    if (isInBasket) {
      return (
        <div style={{textAlign: "center"}}>
          {(edit === 1) && <>
            <p style={{color: "#00FDD4", marginBottom: "5px"}}>zmień dawkę</p>
            <div style={{display: "flex", justifyContent: "center"}}>
              <Button disabled={decreaseRatio === null} id='btn-basket-minus'
                      onClick={() => this.cartAction("decrease", code, decreaseRatio)}>-</Button>
              <div id='btn-basket-display'>{label}</div>
              <Button disabled={increaseRatio === null} id='btn-basket-plus'
                      onClick={() => this.cartAction("increase", code, increaseRatio)}>+</Button>
            </div>
            <Tooltip text={`dopasuj swoją dawkę jeszcze bardziej za pomocą przycisków "+" lub "-"`}/>
          </>}
          <Divider hidden/>
          {(remove === 1) &&
          <span id="remove-link" onClick={() => this.cartAction("remove", code)}><Icon
            name="close"/>usuń pozycję</span>
          }
        </div>
      )
    } else {
      return (
        <Button id='btn-basket-add' onClick={() => this.cartAction("add", code)}>Dodaj do koszyka</Button>
      )
    }
  }

  calculatePrice() {
    let ol = this.props.orderLines;
    let sum = 0;
    _.forEach(ol, function (el) {
      sum += parseFloat(el.price) * el.ratio;
    })
    return sum;
  }

  displayIngredients(code) {
    let ingredients = null;
    _.forEach(this.props.orderLines, (el) => {
      if (el.code === code) {
        ingredients = el.ingredients;
      }
    })
    if (!ingredients) {
      _.forEach(this.props.surveyLines, (el) => {
        if (el.code === code) {
          ingredients = el.ingredients;
        }
      })
    }

    return (
      <>
        {_.map(ingredients, ({name, value}, i: number) => (
          <React.Fragment key={i}>
            <Grid.Row>
              <Grid.Column width={10} textAlign='left' id='ingredients'><b>{t(name)}</b></Grid.Column>
              <Grid.Column width={6} id='ingredients'><span dangerouslySetInnerHTML={{__html: value}}/></Grid.Column>
            </Grid.Row>
          </React.Fragment>
        ))}
      </>
    )
  }


  displayPremixIcons(code, add = true) {
    let icon_set = [];
    let icons = {};
    if (sachetts.includes(code)) {
      icons = {name: 'i-sachetts.svg', desc: 'Saszetka'}
      icon_set.push(icons)
    }
    if (pills.includes(code)) {
      icons = {name: 'i-pills.svg', desc: 'Pigułka'}
      icon_set.push(icons)
    }
    if (notVegan.includes(code)) {
      icons = {name: 'i-lactose-free.svg', desc: 'Nie zawiera laktozy'}
      icon_set.push(icons)
      icons = {name: 'i-gluten-free.svg', desc: 'Nie zawiera glutenu'}
      icon_set.push(icons)
    }
    if (allIconsCodes.includes(code)) {
      icons = {name: 'i-lactose-free.svg', desc: 'Nie zawiera laktozy'}
      icon_set.push(icons)
      icons = {name: 'i-gluten-free.svg', desc: 'Nie zawiera glutenu'}
      icon_set.push(icons)
      icons = {name: 'i-vegan.svg', desc: 'Odpowiedni dla wegetarian'}
      icon_set.push(icons)
    }

    return (
      <>
        <Table.Row disabled={!add}>
          <Table.Cell className="no-border"/>
          <Table.Cell className="no-border">
            <div className="icons-container">
              {_.map(icon_set, ({name, desc}, i: number) => (
                <React.Fragment key={i}>
                  <Segment basic disabled={!add} className='pm-reset'>
                    <Image src={'../../img/' + name} alt="icon" size="mini"/>
                  </Segment>
                  <div className="icons-desc">{desc}</div>
                </React.Fragment>
              ))}
            </div>
          </Table.Cell>
        </Table.Row>
      </>
    )
  }

  surveyPremixes(){
    if(this.props.surveyLines && this.props.surveyLines.length > 0)
    return (
      <Grid.Row>
        <Header as='h2'>Składniki z ankiety:</Header>
        <p>Poniżej rekomendowany skład Twojej miesięcznej kuracji Pure4Me na podstawie wypełnionej ankiety.</p>
        <Divider hidden/>
        <Table basic='very'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell/>
              <Table.HeaderCell id='order-theader'>Składnik</Table.HeaderCell>
              <Table.HeaderCell id='order-theader'>Cena</Table.HeaderCell>
              <Table.HeaderCell id='order-theader' textAlign='right'>Dostosuj</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(this.props.surveyLines, ({code, name, price, edit, ratio, add, remove, desc, ingredients}, i: number) => (
              <React.Fragment key={i}>
                <Table.Row verticalAlign='top'>
                  <Table.Cell width={1}>
                    <Image src={'../../img/' + code + '.svg'} alt="icon" size="tiny"/>
                  </Table.Cell>
                  <Table.Cell verticalAlign='top' width={10}>
                    <p id="cell-header">{_.toLower(name)}</p>
                    <ShowMoreText
                      lines={1}
                      more={<div id='read-more'>rozwiń<Image src={arrow_right} alt="Rozwiń" id='img-more'/></div>}
                      less={<div id='read-less'>zwiń<Image src={arrow_left} alt="Zwiń" id='img-less'/></div>}
                      expanded={false}
                    >
                      <p dangerouslySetInnerHTML={{__html: t(desc)}}/>
                      <Grid celled='internally' textAlign='center'>
                        {this.displayIngredients(code)}
                      </Grid>
                      <br/>
                      <p style={{fontSize: "11px"}}><sup>*</sup>RWS - referencyjna wartość spożycia</p>
                    </ShowMoreText>
                  </Table.Cell>

                  <Table.Cell width={1}>{Number(price).toFixed(2)}</Table.Cell>
                  <Table.Cell width={4}>
                    {(add === 1 || (code === 'z_woman' || code === 'z_man')) ? this.displayButtons(code, edit, remove) : <p>składnik niedostępny</p>}
                  </Table.Cell>
                </Table.Row>
                {this.displayPremixIcons(code)}
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
        <Divider section/>
      </Grid.Row>
    )
  }

  render() {
    return (
      <>
      <div style={{paddingTop: '150px'}}/>
      <Segment basic style={{minHeight: "calc(100vh - 10px)"}}>
        <Container>
          <Grid stackable>
            <Grid.Column width={11}>

              {this.surveyPremixes()}

              <Grid.Row>
                <Header as='h2'>Dostępne składniki:</Header>
                <p>Masz możliwość jeszcze bardziej dopasować swój skład wybierając składniki dostępne dla Ciebie poniżej. Personalizuj dowolnie i sprawdzaj jak zmienia się skład i cena miesięcznej kuracji Pure4Me.</p>
                <Divider hidden/>
                <Table basic='very'>
                  <Table.Body>
                    {_.map(this.props.availablePremixes, ({code, name, price, edit, ratio, add, desc, remove, ingredients}, i: number) => (
                      <React.Fragment key={i}>
                        <Table.Row disabled={!(add === 1 && (code !== 'z_woman' && code !== 'z_man'))}>
                          <Table.Cell verticalAlign='top'>
                            <Image disabled={!(add === 1 && (code !== 'z_woman' && code !== 'z_man'))} src={'../../img/' + code + '.svg'} alt="icon" size="tiny"/>
                          </Table.Cell>

                          <Table.Cell verticalAlign='top'>
                            <p id="cell-header">{_.toLower(name)}</p>
                            <ShowMoreText
                              lines={2}
                              more={(add === 1) &&
                              <div id='read-more'>rozwiń<Image src={arrow_right} alt="Rozwiń" id='img-more'/></div>}
                              less={<div id='read-less'>zwiń<Image src={arrow_left} alt="Zwiń" id='img-less'/></div>}
                              anchorClass=''
                              expanded={false}
                            >
                              <p dangerouslySetInnerHTML={{__html: t(desc)}}/>

                              <Grid celled='internally' textAlign='center'>
                                {_.map(ingredients, ({name, unit, value, rws}, i: number) => (
                                  <React.Fragment key={i}>
                                    <Grid.Row>
                                      <Grid.Column width={10} textAlign='left'
                                                    id='ingredients'><b>{t(name)}</b></Grid.Column>
                                      <Grid.Column width={6} id='ingredients'><span dangerouslySetInnerHTML={{__html: value}}/></Grid.Column>
                                    </Grid.Row>
                                  </React.Fragment>
                                ))}
                              </Grid>
                              <Divider hidden/>
                              <p><sup>*</sup>RWS - referencyjna wartość spożycia</p>
                            </ShowMoreText>
                          </Table.Cell>

                          <Table.Cell width={2} verticalAlign='top'>{(add === 1) && price}</Table.Cell>
                          <Table.Cell width={4} verticalAlign='top'>
                            {(add === 1 && (code !== 'z_woman' && code !== 'z_man')) ? this.displayButtons(code, edit, remove) : <p>składnik niedostępny</p>}
                          </Table.Cell>
                        </Table.Row>
                        {this.displayPremixIcons(code, add)}
                      </React.Fragment>
                    ))}
                  </Table.Body>
                </Table>
              </Grid.Row>
            </Grid.Column>

            <Grid.Column width={5}>
              <OrderSummary
                orderCode={this.props.orderCode}
                orderLines={this.props.orderLines}
                priceBase={this.props.priceBase}
                priceDelivery={this.props.priceDelivery}
                onShowIngredients={this.props.onShowIngredients}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </Segment>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SurveyOrder);