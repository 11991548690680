import * as React from "react"
import {
  Header, Segment, Divider, Container, Grid, Icon, Popup, Image
} from "semantic-ui-react"
import {connect} from "react-redux";
import * as actions from "../../actions";
import _ from "lodash";
import PremixBox from "../utils/PremixBox";
import PremixModal from "../utils/PremixModal";
import OrderBackground from "../backgrounds/order/Order";
import OrderSummary from "./orderSummary";


interface StateProps {
}

export function mapStateToProps(state: any): StateProps {
  return {
  };
}

interface DispatchProps {
  removeFromCart: typeof actions.removeFromCart,
  checkOrder: typeof actions.checkOrder,
}

const mapDispatchToProps = {
  removeFromCart: actions.removeFromCart,
  checkOrder: actions.checkOrder,
};

interface OwnProps {
  availablePremixes: any
  orderCode: string
  orderLines: any
  priceBase: number
  priceDelivery: number
  onShowIngredients: () => void
}

type Props = StateProps & DispatchProps & OwnProps;

interface State {
  genderPremixes: any,
  premixes: any,
  showPremixModal: boolean,
  premixModalData: any,
  genderChosen: boolean
}

class NewOrder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      genderPremixes: null,
      premixes: null,
      showPremixModal: false,
      premixModalData: null,
      genderChosen: false,
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState): void {
    if(prevProps.availablePremixes !== this.props.availablePremixes){
      let ap = this.props.availablePremixes;
      let genderPremixes = [];
      let premixes = [];
      for(let x in ap){
        if(ap[x].code === 'z_man' || ap[x].code === 'z_woman'){
          genderPremixes.push(ap[x]);
        } else {
          premixes.push(ap[x]);
        }
      }
      let genderChosen = false;
      if(this.props.orderLines.filter(({code}) => code === 'z_man' || code === 'z_woman')[0]){
        genderChosen = true;
      }
      this.setState({genderPremixes, premixes, genderChosen});
    }
  }

  removeFromCart(premixCode){
    let b64Code = btoa(this.props.orderCode);
    let formData = {
      code: b64Code,
      orderLinesCode: premixCode,
    }
    this.props.removeFromCart(formData);
  }

  callShowModal(premixCode){
    let filtered = this.props.availablePremixes.filter(({code}) => (
      code === premixCode
    ));
    this.setState({showPremixModal: true, premixModalData: filtered[0]});
  }

  callCheckOrder(){
    this.props.checkOrder(btoa(this.props.orderCode));
  }

  calculatePrice() {
    let ol = this.props.orderLines;
    let sum = 0;
    _.forEach(ol, function (el) {
      sum += parseFloat(el.price) * el.ratio;
    })
    return sum;
  }

  iconPopup(){
    return (
      <Popup
        trigger={<Icon as={Image} src={'/img/qmark.png'} size='mini'/>}
      >
        <Popup.Content>
          Twój suplement jest wyjątkowy, dlatego ręcznie przygotuje, sprawdzi i spakuje go dedykowany specjalista. 
          Nie doda żadnych wypełniaczy ani konserwantów, bo produkt od razu zostanie wysłany do Ciebie.
        </Popup.Content>
      </Popup>
    )
  }

  render() {
    return (
      <>
        <OrderBackground><div/></OrderBackground>
        <div style={{paddingTop: '150px'}}/>
          <Container>
            <Grid>
              <Grid.Column computer='11' mobile={16} tablet={11}>
                <Header as='h1' style={{fontSize: '3em'}}><b>Skomponuj skład</b><br className="computer-only"/> swojego suplementu</Header>
                <p>Wybrałeś opcję <b>samodzielnej personalizacji Twojego pure4me.</b> <br />
                Wybierz składniki, a my wyprodukujemy dla Ciebie <b>miesięczną porcję zdrowia i energii.</b></p>
              </Grid.Column>
            </Grid>
          </Container>
        <Segment basic style={{minHeight: "calc(100vh - 10px)", padding: 0}}>
          <PremixModal 
            orderCode={this.props.orderCode}
            data={this.state.premixModalData} 
            showModal={this.state.showPremixModal} 
            closeModal={() => this.setState({showPremixModal: false})}
            basket={(this.props.orderLines.filter(({code}) => code === this.state.premixModalData?.code))[0]}
          />
          <div className='new-order-title'>
            <Container>
              <Grid stackable>
                <Grid.Column width={11} style={{zIndex: 2}}>
                <Grid.Row>
                    <Header as='h2'>Wybierz składnik płci:</Header>
                    <p><b>Wybierz jeden ze składników płci</b>, aby poprawnie przeprowadzić zamówienie i dopasować produkt do siebie.</p>
                    <Divider hidden/>
                    <Segment basic padded style={{paddingTop: 0, paddingBottom: 0}}>
                      <Grid columns={2} stackable>
                        {_.map(this.state.genderPremixes, (premix, i: number) => (
                          <React.Fragment key={i}>
                            <Grid.Column width={8}>
                              <PremixBox premix={premix}
                                basket={(this.props.orderLines.filter(({code}) => code === premix.code))[0]}
                                orderCode={this.props.orderCode}
                                onDetails={this.callShowModal.bind(this)}
                                gender
                                disabled={this.state.genderChosen}
                              />
                            </Grid.Column>
                          </React.Fragment>
                        ))}
                      </Grid>
                    </Segment>
                  </Grid.Row>
                </Grid.Column>
              </Grid>
            </Container>
          </div>
          <Container>
            <Grid stackable>
              <Grid.Column width={11} style={{zIndex: 2}}>
                <Grid.Row>
                  <Segment basic style={{padding: 0}} disabled={!this.state.genderChosen} className="new-order-available-premixes">
                  <Header as='h2'>Dostępne składniki:</Header>
                  <p>Masz możliwość jeszcze bardziej dopasować swój skład wybierając składniki dostępne dla Ciebie poniżej. Personalizuj dowolnie i sprawdzaj jak zmienia się skład i cena miesięcznej kuracji Pure4Me.</p>
                  <Divider hidden/>
                  <Segment basic padded style={{paddingTop: 0, paddingBottom: 0}}>
                  <Grid columns={2} stackable>
                  {_.map(this.state.premixes, (premix, i: number) => (
                    <React.Fragment key={i}>
                      <Grid.Column width={8}>
                        <PremixBox premix={premix}
                          basket={(this.props.orderLines.filter(({code}) => code === premix.code))[0]}
                          orderCode={this.props.orderCode}
                          onDetails={this.callShowModal.bind(this)}
                          disabled={!this.state.genderChosen}
                        />
                      </Grid.Column>
                    </React.Fragment>
                  ))}
                  </Grid>
                  </Segment>
                  </Segment>
                </Grid.Row>
              </Grid.Column>
            </Grid>
            <Container id="new-order-summary-container">
              <Grid stackable>
                <Grid.Column width={11}/>
                <Grid.Column width={5} className={'new-order-summary'} style={{zIndex: 2}}>
                  <OrderSummary
                    orderCode={this.props.orderCode}
                    orderLines={this.props.orderLines}
                    priceBase={this.props.priceBase}
                    priceDelivery={this.props.priceDelivery}
                    onShowIngredients={this.props.onShowIngredients}
                  />
                </Grid.Column>
              </Grid>
            </Container>
          </Container>
        </Segment>
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewOrder);