import * as React from 'react';
import { Divider, Grid, Header, Modal, Image, Segment } from "semantic-ui-react";
import { t } from '../../i18n';
import _ from "lodash";
import PremixButton from './PremixButton';

const convertName = (name) => {
  let newName = name.toLowerCase();
  return newName.charAt(0).toUpperCase() + newName.slice(1);
}

const PremixModal = ({orderCode, data, showModal, closeModal, basket}) => {
  return (
    <Modal basic
      className='premix-modal'
      open={showModal}
      dimmer={<Modal.Dimmer inverted style={{backgroundColor: "rgba(255,255,255, .98)"}}/>}
      centered={true}
      onClose={closeModal}
      closeIcon
      size='large'
    >
      {data && <>
        <Modal.Content>
          <Grid stackable>
            <Grid.Row columns={2}>
              <Grid.Column width={7} verticalAlign='middle'>
                <Image centered verticalAlign='middle' src={`/img/premixes/${data.code}_big.png`}/>
              </Grid.Column>
              <Grid.Column width={9}>
                <Header as='h2'>{convertName(data.name)}</Header>
                <p dangerouslySetInnerHTML={{__html: t(data.desc)}}/>
                <Grid celled='internally' textAlign='center'>
                  {_.map(data.ingredients, ({name, value}, i: number) => (
                    <React.Fragment key={i}>
                      <Grid.Row>
                        <Grid.Column width={10} textAlign='left'
                          id='ingredients'><b>{t(name)}</b></Grid.Column>
                        <Grid.Column width={6} id='ingredients'><span dangerouslySetInnerHTML={{__html: value}}/></Grid.Column>
                      </Grid.Row>
                    </React.Fragment>
                  ))}
                </Grid>
                <Divider hidden/>
                <p><sup>*</sup>RWS - referencyjna wartość spożycia</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Segment basic className='premix-modal-button-container'>
            <PremixButton orderCode={orderCode} premix={data} basket={basket}/>
          </Segment>

        </Modal.Content>
      </>}
    </Modal>
  )
}

export default PremixModal;