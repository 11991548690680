import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { BackgroundImg } from '../BackgroundImg';

const OrderBackground = ({children}) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "backgrounds/order/order_bg.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 90
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  

  return (
    <BackgroundImg image={data.desktop} style={{zIndex: '-100', width: '100%', height: '90vh', padding: 0, position: 'absolute'}} className='background-top-center'>
      {children}
    </BackgroundImg>
  )
}

export default OrderBackground;
